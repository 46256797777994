<template>
    <div class="page">
        <van-nav-bar
                title="我的信用"
                left-text="返回"
                left-arrow
                @click-left="$router.back(-1)"
        />
       <div class="background-box">
            <div class="card-box">
                <div class="card-top">
                    <span>我的信用值 <span> 4.5星</span></span>
                </div>
                <div class="card-bottom">
                    <p>您近期被投诉过3次,请注意您的服务与承诺,信用值过低将影响您的招工进度!</p>
                </div>
            </div>


       </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {
                },

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }

</script>

<style lang="less" scoped>
    .background-box {
        width: 100%;
        min-height: calc(100vh - 0px);
        background:linear-gradient(#1088FC, #FFFFFF);
        display: flex;
        justify-content: space-around;
        .card-box{
            width: 90%;
            height: 180px;
            /*border: 1px solid red;*/
            margin-top: 70px;
            background: linear-gradient(#d7ebfd, #fcfdfe);
            border-radius: 7px;
            .card-top{
                height: 60px;
                border-bottom: 1px solid #e4eff9;
                padding: 40px 0 0 20px;
                span:first-child{
                        color: #3c3c3c;
                        font-weight: 600;
                        font-size: 16px;
                            span{
                                color: #3b83ce;
                                font-weight: 600;
                                font-size: 20px;
                                margin-left: 5px;
                            }
                    }
            }
            .card-bottom{
                padding: 10px;
                color: #3C3C3C;
            }
        }
    }

</style>
