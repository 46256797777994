import { render, staticRenderFns } from "./myCredit.vue?vue&type=template&id=3dcab120&scoped=true&"
import script from "./myCredit.vue?vue&type=script&lang=js&"
export * from "./myCredit.vue?vue&type=script&lang=js&"
import style0 from "./myCredit.vue?vue&type=style&index=0&id=3dcab120&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcab120",
  null
  
)

export default component.exports